import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import LayoutMain from './layout/LayoutMain';

const theme = createTheme({});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutMain />
    </ThemeProvider>
  );
};

export default App;
