import { kebabCase, upperFirst } from '@s-libs/micro-dash';
import { useEffect, useMemo } from 'react';
import mockData, { BeverageMenuItem } from './mockData';

export type TopLevelCategory = {
  key: string;
  route: string;
  displayLabel: string;
  menuItems: BeverageMenuItem[];
};

const getCategoryAsKey = (cat: string) => kebabCase(cat.toLowerCase());

export default function useMenuData() {
  const result = useMemo(() => {
    const categories = mockData.reduce((acc, curr) => {
      const asKey = getCategoryAsKey(curr.Category);

      if (acc.includes(asKey)) {
        return acc;
      }
      return [...acc, asKey];
    }, [] as string[]);

    const categoriesMeta: TopLevelCategory[] = categories.map(o => ({
      key: o,
      route: o,
      displayLabel: o
        .split('-')
        .map(str => upperFirst(str))
        .join(' '),
      menuItems: [],
    }));

    const categoriesMapped = mockData.reduce((acc, curr) => {
      const asKey = getCategoryAsKey(curr.Category);
      const keyExists = Object.keys(acc).includes(asKey);

      if (keyExists) {
        const menuItemsAppended = [...acc[asKey].menuItems, curr];
        return { ...acc, [asKey]: { ...acc[asKey], menuItems: menuItemsAppended } };
      } else {
        const newCategory: TopLevelCategory = {
          key: asKey,
          route: asKey,
          displayLabel: curr.Category,
          menuItems: [curr],
        };
        return {
          ...acc,
          [asKey]: newCategory,
        };
      }

      // return keyExists
      //   ? { ...acc, [asKey]: { ...acc[asKey], menuItems: [...acc[asKey].menuItems, curr] } }
      //   : {
      //       ...acc,
      //       [asKey]: { menuItems: [curr] },
      //     };
    }, {} as Record<string, TopLevelCategory>);
    return { categoryKeys: Object.keys(categoriesMapped), categories: categoriesMapped, menuItems: mockData };
  }, []);
  console.log('useMenuData', result);
  return result;
}
