import { Switch, Route } from 'wouter';
import MenuCategories from './MenuCategories';
import TopLevelCategoryMenu from './TopLevelCategoryMenu';

export default function ContentMain() {
  return (
    <Switch>
      <Route<{ type: string }> path="/menu/:type">{params => <TopLevelCategoryMenu {...params} />}</Route>
      <Route path="/" component={MenuCategories} />
    </Switch>
  );
}
