import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from 'react';
import { MenuItemCategoryCard } from './TopLevelCategoryCards';
import useMenuData from './useMenuData';

export default function MenuCategories() {
  const { categories } = useMenuData();
  const categoryArray = Object.values(categories);
  const [apiResponse, setApiResponse] = useState(null as null | string);

  const dispatch = async () => {
    const response = await fetch('api/getMenuAsync');
    console.log('response', response);
    const jsonData = await response.text();
    console.log(jsonData);

    setApiResponse(JSON.stringify(jsonData));
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ py: 5 }}>
        <button onClick={dispatch}>click to dispatch test</button>
        <Box sx={{ py: 3 }}>
          response: <pre>{apiResponse}</pre>
        </Box>
      </Box>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {categoryArray.map(({ key, route, displayLabel }) => (
          <Grid key={`key-${key}`} xs={6} display="flex" justifyContent="center" alignItems="center">
            <MenuItemCategoryCard categoryLabel={displayLabel} categoryRoute={`/menu/${route}`} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
