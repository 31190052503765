import * as React from 'react';
import { Link } from 'wouter';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import WineBarIcon from '@mui/icons-material/WineBar';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import HelpIcon from '@mui/icons-material/Help';

const iconMappings = {
  beer: SportsBarIcon,
  wine: WineBarIcon,
  cocktails: LocalBarIcon,
  spirits: LiquorIcon,
  help: HelpIcon,
};

function SidebarMenuItem({
  linkTo,
  displayLabel,
  open,
  active,
}: {
  linkTo: string;
  displayLabel: string;
  open: boolean;
  active?: boolean;
}) {
  const getIconComponent = (key: string) => {
    switch (key) {
      case 'beer':
      case 'wine':
      case 'cocktails':
      case 'spirits':
      case 'help':
        return iconMappings[key];
      default:
        return LocalDrinkIcon;
    }
  };

  const IconComponent = getIconComponent(linkTo);
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <Link href={`/menu/${linkTo}`}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
          selected={active}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            <IconComponent />
          </ListItemIcon>
          <ListItemText primary={displayLabel} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </Link>
    </ListItem>
  );
}

export default function Sidebar({
  open,
  categories,
}: {
  open: boolean;
  categories: { displayLabel: string; route: string; active: boolean }[];
}) {
  return (
    <>
      <List>
        {categories.map(({ displayLabel, route, active }, index) => (
          <SidebarMenuItem key={`${route}-${index}`} linkTo={route} displayLabel={displayLabel} open={open} active={active} />
        ))}
      </List>
      <Divider />
      <List>
        <SidebarMenuItem linkTo={'help'} displayLabel="Help & Feedback" open={open} active={false} />
      </List>
    </>
  );
}
